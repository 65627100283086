// app/providers.tsx
"use client";
import { STAGE } from "@/lib/env";
import {
  UTM_AFFILIATE_ID_QUERY_PARAM,
  UTM_AFFILIATE_NAME_QUERY_PARAM,
  UTM_AFFILIATE_TYPE_QUERY_PARAM,
} from "@/lib/utm";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || "", {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://eu.posthog.com",
    loaded: (posthog) => {
      if (!["dev", "prod"].includes(STAGE)) posthog.debug();
    },
    custom_campaign_params: [
      UTM_AFFILIATE_ID_QUERY_PARAM,
      UTM_AFFILIATE_TYPE_QUERY_PARAM,
      UTM_AFFILIATE_NAME_QUERY_PARAM,
    ],
  });
}

/**
 * Analytics and tracking
 */
export function PHProvider({ children }: { children: React.ReactNode }) {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
