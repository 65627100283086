import(/* webpackMode: "eager" */ "/tmp/seed/source/packages/webapp/components/AudioPlayerBackground.tsx");
import(/* webpackMode: "eager" */ "/tmp/seed/source/packages/webapp/context/AudioPlayerContext.tsx");
import(/* webpackMode: "eager" */ "/tmp/seed/source/packages/webapp/context/AuthContext.tsx");
import(/* webpackMode: "eager" */ "/tmp/seed/source/packages/webapp/context/DialogContext.tsx");
import(/* webpackMode: "eager" */ "/tmp/seed/source/packages/webapp/context/NotificationContext.tsx");
import(/* webpackMode: "eager" */ "/tmp/seed/source/packages/webapp/context/NowPlayingViewContext.tsx");
import(/* webpackMode: "eager" */ "/tmp/seed/source/packages/webapp/context/PosthogContext.tsx");
import(/* webpackMode: "eager" */ "/tmp/seed/source/packages/webapp/context/UserProfileContext.tsx");
import(/* webpackMode: "eager" */ "/tmp/seed/source/packages/webapp/context/VenueStreamPlayerContext.tsx");
import(/* webpackMode: "eager" */ "/tmp/seed/source/packages/webapp/context/WorkspaceContext.tsx");
import(/* webpackMode: "eager" */ "/tmp/seed/source/packages/webapp/lib/app-router-trpc/trpc-provider.tsx");
import(/* webpackMode: "eager" */ "/tmp/seed/source/packages/webapp/public/globals.css");
import(/* webpackMode: "eager" */ "/tmp/seed/source/node_modules/.pnpm/next@14.1.0_@babel+core@7.25.7_react-dom@18.2.0_react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}")